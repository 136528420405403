import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  Image,
  extendTheme,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react';

import { motion } from 'framer-motion';
import { ProjectGridItem } from './components/gridItem';
import Section from './components/section';

import thumbCanvas from './service-imgs/canvas.png';
import thumb365 from './service-imgs/365.png';
import thumbNESS from './service-imgs/ness.png';
import thumbNUReflect from './service-imgs/nureflect.png';
import thumbLibrary from './service-imgs/library.png';
import thumbNUSU from './service-imgs/nusu.png';
import thumbNUPrint from './service-imgs/nuprint.png';
// import thumbStudentPortal from './service-imgs/studentportal.png';
// import thumbTimetable from './service-imgs/timetable.png';

import { ArrowForwardIcon, CalendarIcon, SunIcon } from '@chakra-ui/icons';

// import { ColorModeSwitcher } from './ColorModeSwitcher';

function App() {
  const theme = extendTheme({
    styles: {
      global: () => ({
        body: {
          bg: 'linear-gradient(69.9deg, rgb(1, 58, 103) 3.2%, rgb(0, 108, 181) 97.6%);',
          color: 'white',
        },
      }),
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Flex alignItems="center" justifyContent="center">
          <Flex
            width="90vh"
            direction="column"
            p={12}
            rounded={5}
            alignItems="center"
          >
            {/* Motion div for the logo */}
            <motion.div
              initial={{
                y: -150,
                x: 0,
                opacity: 0,
                scale: 0.3,
                rotateX: -180,
                rotateZ: 80,
              }}
              animate={{
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                rotateX: 0,
                rotateZ: 0,
              }}
              transition={{
                ease: [0.42, 0, 0, 1.08],
                duration: 1.2,
                delay: 0.2,
                type: 'spring',
              }}
            >
              <Image
                src="https://cdn.linkxr.one/official_resources/NuLink-Logo.webp"
                draggable="false"
                width="80vw"
              />
            </motion.div>

            <motion.div
              initial={{
                y: -150,
                opacity: 0,
                scaleX: 2,
                scaleZ: 0.4,
              }}
              animate={{
                y: 0,
                opacity: 1,
                scaleY: 1,
                scaleX: 1,
              }}
              transition={{
                duration: 1.5,
                delay: 1.3,
                type: 'spring',
              }}
            >
              <Text>All of Newcastle University, one click away.</Text>
            </motion.div>

            {/* Student Portal and Timetable links */}
            <SimpleGrid columns={[1, 1, 2]} spacing={10} width="100%" mt={5}>
              <motion.div
                initial={{
                  y: -150,
                  x: 0,
                  opacity: 0,
                  rotateZ: 180,
                }}
                animate={{
                  y: 0,
                  x: 0,
                  opacity: 1,
                  rotateZ: 0,
                }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  type: 'spring',
                }}
              >
                <Link
                  href={'https://studentportal.ncl.ac.uk/dashboard/'}
                  target="_blank"
                >
                  <Box
                    width="100%"
                    height="80px"
                    bg="linear-gradient(69.9deg, rgb(0, 108, 181) 3.2%, rgb(214, 9, 40) 97.6%);"
                    mt={0}
                    borderRadius={25}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text>
                      Your Student Portal <ArrowForwardIcon mb={1} />
                    </Text>
                  </Box>
                </Link>
              </motion.div>

              <motion.div
                initial={{
                  y: -150,
                  x: 0,
                  opacity: 0,
                  rotateZ: -180,
                }}
                animate={{
                  y: 0,
                  x: 0,
                  opacity: 1,
                  rotateZ: 0,
                }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  type: 'spring',
                }}
              >
                <Link
                  href={'https://nu-studentportal.ncl.ac.uk/Timetable'}
                  target="_blank"
                >
                  <Box
                    width="100%"
                    height="80px"
                    bg="linear-gradient(69.9deg, rgb(0, 108, 181) 3.2%, rgb(0, 0, 139) 97.6%);"
                    mt={[-4, -4, 0]}
                    borderRadius={25}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text>
                      Your Timetable <CalendarIcon mb={1} />
                    </Text>
                  </Box>
                </Link>
              </motion.div>
            </SimpleGrid>

            <SimpleGrid columns={[1, 1, 2]} spacing={10} mt={5}>
              <Section delay={0.6}>
                <ProjectGridItem
                  title="Canvas"
                  thumbnail={thumbCanvas}
                  url="https://ncl.instructure.com/"
                >
                  Your courses & modules
                </ProjectGridItem>
              </Section>

              <Section delay={0.7}>
                <ProjectGridItem
                  title="NESS"
                  thumbnail={thumbNESS}
                  url="https://ness.ncl.ac.uk/"
                >
                  Submission Deadlines, Academic Progress
                </ProjectGridItem>
              </Section>

              <Section delay={0.8}>
                <ProjectGridItem
                  title="Microsoft 365 Home"
                  thumbnail={thumb365}
                  url="https://login.microsoftonline.com/"
                >
                  Create, share and collaborate all in one place
                </ProjectGridItem>
              </Section>

              <Section delay={0.9}>
                <ProjectGridItem
                  title="NU Reflect"
                  thumbnail={thumbNUReflect}
                  url="https://reflect.ncl.ac.uk/"
                >
                  Your personal diary
                </ProjectGridItem>
              </Section>

              <Section delay={1.0}>
                <ProjectGridItem
                  title="Newcastle University Library"
                  thumbnail={thumbLibrary}
                  url="http://libsearch.ncl.ac.uk/"
                >
                  Search for books, databases, journals, articles & more
                </ProjectGridItem>
              </Section>

              <Section delay={1.1}>
                <ProjectGridItem
                  title="NUPrint"
                  thumbnail={thumbNUPrint}
                  url="https://nuprint.ncl.ac.uk/index.cfm"
                >
                  Get your documents printed.
                </ProjectGridItem>
              </Section>
            </SimpleGrid>

            <SimpleGrid columns={1} spacing={10} mt={5}>
              <Section delay={1.2}>
                <ProjectGridItem
                  title="NUSU"
                  thumbnail={thumbNUSU}
                  url="https://nusu.co.uk/"
                >
                  Students' Union
                </ProjectGridItem>
              </Section>
            </SimpleGrid>

            {/* Submit your feedback link */}
            <SimpleGrid columns={1} spacing={10} width="100%">
              <motion.div
                initial={{
                  y: -150,
                  x: 0,
                  opacity: 0,
                  rotateX: 360,
                }}
                animate={{
                  y: 0,
                  x: 0,
                  opacity: 1,
                  rotateX: 0,
                }}
                transition={{
                  duration: 0.8,
                  delay: 1.5,
                  type: 'spring',
                }}
              >
                <Link href={'https://tally.so/r/3qKDaO'} target="_blank">
                  <Box
                    width="100%"
                    height="80px"
                    bg="rgb(1, 58, 103);"
                    mt={0}
                    borderRadius={50}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text>
                      Send us your feedback! <SunIcon mb={1} />
                    </Text>
                  </Box>
                </Link>
              </motion.div>
            </SimpleGrid>
          </Flex>
        </Flex>

        <Box align="center" opacity={0.4} fontSize="sm" mb={8}>
          Created with &lt;3 by{' '}
          <Link
            href="https://savvydev.me"
            style={{
              background: '-webkit-linear-gradient(45deg, #3386FA, #01F796)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            SavvyDev
          </Link>
          . For the students.
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
