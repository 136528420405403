// import NextLink from "next/link";
import { Box, Image, Link, Text } from '@chakra-ui/react';
import { Global } from '@emotion/react';

export const ProjectGridItem = ({ children, url, title, thumbnail }) => (
  <Box w="100%" align="center">
    <Link href={url} target="_blank">
      {/* <LinkBox cursor="pointer"> */}
      <Image
        src={thumbnail}
        alt={title}
        className="grid-item-thumbnail"
        placeholder="blur"
        borderRadius={20}
      />
      {/* <LinkOverlay href={`/projects/${id}`}> */}
      <Text mt={2} fontSize={20}>
        {title}
      </Text>
      {/* </LinkOverlay> */}
      <Text fontSize={14}>{children}</Text>
      {/* </LinkBox> */}
    </Link>
  </Box>
);

export const GridItemStyle = () => (
  <Global
    styles={`
          .grid-item-thumbnail {
              border-radius: 12px;
          }
          `}
  />
);
